/*!
 * Link click loading
 */

if (window.performance) { // キャッシュで戻った場合再遷移防止
  window.onpageshow = function (event) {
    if (event.persisted) {
      window.location.reload();
    }
  };
}
window.addEventListener('DOMContentLoaded', () => {
  const linkArray = Array.prototype.slice.call(document.querySelectorAll('a[href]:not([target="_blank"]):not([data-loading="disabled"])'), 0);
  const loadingElement = document.querySelector('#loading');
  const loadingBackground = document.querySelector('#loading>span:last-child');
  const activeClass = "is-active";

  linkArray.forEach(link => {
    link.addEventListener('click', e => {
      e.preventDefault();
      /// add class
      loadingElement.classList.add(activeClass);
      if(link.dataset.loading){
        loadingElement.classList.add("is-" + link.dataset.loading);
      }
      // location href
      loadingBackground.addEventListener('animationend', () => {
        location.href = link.href;
      });
    });
  });
});
