/*!
 * Swiper
 * use : Swiper(CDN)
 */

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelectorAll('[swiper-top-main] .swiper-container .swiper-slide').length > 1) {
    new Swiper("[swiper-top-main] .swiper-container", {
      loop: true,
      slidesPerView: 1,
      autoplay: {
        delay: 3000,
      }
    });
  }

  new Swiper("[swiper-top] .swiper-container", {
    loop: false,
    slidesPerView: "auto",
    centeredSlides: false,
    freeMode: true,
    spaceBetween: 10,
    breakpoints: {
      768: {
        freeMode: false,
        centeredSlides: false,
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
  });

});

